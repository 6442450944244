import { Component, ElementRef, Input, inject } from '@angular/core';
import { type AbstractControl, type FormControl, type UntypedFormControl } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

const MESSAGE_KEYS = {
  // Angular default validators
  required: 'common.errorMessages.isRequired',
  email: 'common.errorMessages.emailInvalid',
  invalid: 'common.errorMessages.isInvalid',
  requiredtrue: 'common.errorMessages.isRequired',
  minlength: 'common.errorMessages.minLength',
  maxlength: 'common.errorMessages.maxLength',

  // Custom validators
  unexpected: 'common.errorMessages.unexpected',
  alphanumericWithSpaceInvalid: 'common.errorMessages.alphanumericWithSpaceInvalid',
  emailInvalid: 'common.errorMessages.emailInvalid',
  phoneInvalid: 'common.errorMessages.phoneInvalid',
  numberInvalid: 'common.errorMessages.numberInvalid',
  websiteInvalid: 'common.errorMessages.websiteInvalid',
  urlInvalid: 'common.errorMessages.urlInvalid',
  customPatternInvalid: 'common.errorMessages.customPatternInvalid',
};

@Component({
  selector: 'form-error-messages',
  templateUrl: './form-error-messages.component.html',
  styleUrls: ['./form-error-messages.component.scss'],
  imports: [TranslateModule],
  standalone: true,
})
export class FormErrorMessagesComponent {
  private readonly elementRef = inject(ElementRef);
  private readonly translateService = inject(TranslateService);
  @Input() control: UntypedFormControl | FormControl | AbstractControl;
  @Input() showErrors = true;
  @Input() customErrors: any;
  @Input() controlName: string;

  public get isVisible(): boolean {
    return this.showErrors && this.control.errors && this.control.invalid;
  }

  public get label(): string {
    if (this.controlName) {
      return this.controlName;
    }

    const element = this.elementRef.nativeElement as HTMLElement;
    const label = element.parentElement.querySelector('label');
    return label ? label.innerText : '';
  }

  public get errorMessage(): string {
    const firstErrorKey = Object.keys(this.control.errors)[0];
    if (!firstErrorKey) {
      return '';
    }

    // checking custom errors first
    if (this.customErrors?.[firstErrorKey]) {
      return this.customErrors[firstErrorKey];
    }

    // client side validation
    if (MESSAGE_KEYS[firstErrorKey]) {
      return MESSAGE_KEYS[firstErrorKey];
    }

    // server side validation
    const serverErrorPath = `common.serverErrors.${firstErrorKey}`;
    if (this.translateService.instant(serverErrorPath) !== serverErrorPath) {
      return serverErrorPath;
    }

    // if no translation found, trying to use error's value
    if (typeof this.control.errors[firstErrorKey] === 'string') {
      return this.control.errors[firstErrorKey];
    }

    return '';
  }

  public get errorTranslateOptions(): any {
    const options: any = {
      label: this.label,
    };

    if (this.control.errors.maxlength) {
      options.value = this.control.errors.maxlength.requiredLength;
    }

    if (this.control.errors.minlength) {
      options.value = this.control.errors.minlength.requiredLength;
    }

    return options;
  }
}
